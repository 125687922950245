<script>
import GridPhotogroups from 'storemoodify/lib/GridPhotogroups'
import PhotogroupOverview from './PhotogroupOverview'

export default {
    functional: true,
    render(createElement, context) {
        const scopedSlots = Object.assign({}, context.scopedSlots, {
            photogroup: ({ photogroup }) => createElement(PhotogroupOverview, { props: { photogroup } })
        });

        return createElement(GridPhotogroups, Object.assign({}, context, {
            scopedSlots,
            on: context.listeners
        }));
    }
}
</script>
