<template>
    <div class="photogroup-overview">
        <div class="headline font-weight-bold" @click="onClickPhotoset()">{{ $t(`headers['photoset']`) }}</div>
        <div class="subtitle-1"> {{ $t(`rudiments.place`, [photogroup.place.name]) }}</div>
        <div class="subtitle-1">{{ $t(`headers['photographer']`) }} {{photogroup.photographer.name}}</div>
        <div class="subtitle-1"> {{ $t(`rudiments.photos`, [photogroup.photos.length]) }}</div>
        <div class="subtitle-1 d-flex">
            <v-icon class="mr-2">mdi-calendar-range</v-icon>
            {{photogroup.startTime | unixDateTime}} - {{photogroup.endTime | unixDateTime}}
        </div>
    </div>
</template>
<script>
export default {
    props: {
        photogroup: {
            type: Object,
            default: null,
        },
    },
    methods: {
        onClickPhotoset() {
            this.$router.push(`/photoset/${this.photogroup.id}`)
        },
    },
}
</script>
<style lang="stylus">
    .photogroup-overview {
        padding: 8px 16px;
    }

    .photogroup-overview .headline {
        cursor pointer
    }
</style>
